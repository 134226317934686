import { CSSProperties } from 'react';

import { cn } from '@/shared/lib/cn';

type Type = 'circle' | 'ellipse';

type Origin =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right';

interface RadialProps {
  /**
   * The type of radial gradient
   * @default circle
   * @type string
   */
  type?: Type;
  /**
   * The color to transition from
   * @default #00000000
   * @type string
   * */
  from?: string;

  /**
   * The color to transition to
   * @default #290A5C
   * @type string
   * */
  to?: string;

  /**
   * The size of the gradient in pixels
   * @default 300
   * @type number
   * */
  size?: number;

  /**
   * The origin of the gradient
   * @default center
   * @type string
   * */
  origin?: Origin;

  /**
   * The class name to apply to the gradient
   * @default ""
   * @type string
   * */
  className?: string;
}

export const GradientBg = (props: RadialProps) => {
  const {
    type = 'ellipse',
    from = 'rgba(120,119,198,0.4)',
    to = 'hsla(0, 0%, 0%, 0)',
    size = 300,
    origin = 'center',
    className,
  } = props;

  const styles: CSSProperties = {
    width: `${size * 2}px`,
    height: `${size * 2}px`,
    '--radial-type': type,
    '--radial-size': `${size}px`,
    '--radial-origin': origin,
    '--radial-from': from,
    '--radial-to': to,
    zIndex: -1,
  };

  return (
    <div
      className={cn(
        'pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform',
        'bg-radial',
        className,
      )}
      style={styles}
    />
  );
};
