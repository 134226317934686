import wretch from 'wretch';

import { env } from '@/shared/config/env.mjs';

import { NetworkException } from './exceptions';

export const httpClient = wretch(env.NEXT_PUBLIC_API_ENDPOINT)
  .catcher(500, () => {
    throw new NetworkException('Internal Server Error').toJSON();
  })
  .catcher(404, () => {
    throw new NetworkException('NotFound').toJSON();
  })
  .catcher(429, () => {
    throw new NetworkException('TooManyRequests').toJSON();
  })
  .resolve((_) =>
    _.fetchError(() => {
      throw new NetworkException('Network Error').toJSON();
    }),
  );
