import React from 'react';

import { GradientBg } from '@/shared/components/animation/gradient-background';
import { cn } from '@/shared/lib/cn';

interface ArticleProps {
  children: React.ReactNode;
  tag?: 'article' | 'main';
  withContainer?: boolean;
  className?: string;
}

export const Article = (props: ArticleProps) => {
  const { children, tag = 'article', withContainer = true, className } = props;

  const ComponentTag = tag as keyof JSX.IntrinsicElements;

  return (
    <div className={tag === 'main' ? 'mt-[69px] overflow-hidden' : ''}>
      <ComponentTag
        className={cn(
          'py-8 sm:py-10',
          {
            container: withContainer,
            'relative flex min-h-[calc(100vh-70px)] flex-col place-content-center 2xl:min-h-[70vh] 2xl:py-20':
              tag === 'main',
          },
          className,
        )}
      >
        <>
          <div className="z-10">{children}</div>
          {tag === 'main' && <GradientBg className="z-0 sm:hidden" type="circle" origin="center" size={400} />}
        </>
      </ComponentTag>
    </div>
  );
};
