import { cn } from '@/shared/lib/cn';

import { TextFade } from '../fade-text';

interface TextGradientProps {
  text: string;
  tag?: string;
  className?: string;
}

export const TextGradient = (props: TextGradientProps) => {
  const { text, tag = 'h1', className } = props;

  const textClasses = 'font-semibold !leading-[115%] tracking-tighter';

  const gradientClasses =
    'text-balance bg-gradient-to-br from-black/85 from-30% to-black/60 bg-clip-text text-transparent dark:from-white dark:to-white/40';

  return <TextFade text={text} tag={tag} className={cn(textClasses, gradientClasses, className)} />;
};
